@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply w-full mx-auto max-w-[1400px];
  }
}

@font-face {
  font-family: 'sigmar';
  src: url("./assets/fonts/Sigmar_One/SigmarOne-Regular.ttf") format("opentype");
}

@font-face {
  font-family: 'barlow';
  src: url("./assets/fonts/Barlow/Barlow-Light.ttf") format("opentype");
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #15151975;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5f677c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
